import { IDataCar } from ".";

export default {
    id: "car_3008",
    urlPageId: "3008",
    title: "PEUGEOT SUV 3008",
    shortTitle: "3008",
    colors: [
        { colorHex: "#832223", title: "Kırmızı (Ultimate)", dir: "kirmizi" },
        { colorHex: "#8d9095", title: "Gri (Tekno)", dir: "gri" },
        { colorHex: "#404040", title: "Gri (Platinyum)", dir: "gri_platinium", featured: true },
        { colorHex: "#26495f", title: "Mavi (Ada)", dir: "mavi_ada" },
        { colorHex: "#f1f1f1", title: "Beyaz (Sedef)", dir: "beyaz" },
        { colorHex: "#282828", title: "Siyah (İnci)", dir: "siyah" },
    ],
    infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/3008.html",
    otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/3008.html",
    broshureLink: "/assets/files/3008-SUV-KATALOG-LITE-2021.pdf",
    appointmentLink: 'yeni3008/suv',
    galleryPhotos: [
        { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" }, { source: "gallery_3.jpg" },
        { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" }, { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" },
        { source: "gallery_8.jpg" }, { source: "gallery_9.jpg" }, { source: "gallery_10.jpg" }, { source: "gallery_11.jpg" },
        { source: "gallery_12.jpg" }, { source: "gallery_13.jpg" }, { source: "gallery_14.jpg" }, { source: "gallery_15.jpg" },
        { source: "gallery_16.jpg" }, { source: "gallery_17.jpg" },
    ],
    videos: [
        { title: "Değişim Zamanı", type: "youtube", source: "m-1zXtqZFEc" },
        { title: "Değişim Zamanı 2", type: "youtube", source: "YgExEIPFwsg" },
        { title: "Değişim Zamanı 3", type: "youtube", source: "Si0j-mPBIkM" },
        { title: "Değişim Zamanı 4", type: "youtube", source: "HgS6n__XAV4" },
        { title: "Değişim Zamanı 5", type: "youtube", source: "eqwNkRU8gu8" },
    ],
    interiorImageNames: [
        "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
    ],
    pinsInterior: [
        {
            id: "sunroof-i", coords: { x: -88, y: 33 },
            speechKeywords: "cam tavan, iç tavan, tavan, sunroof",
        }, {
            id: "cockpit", coords: { x: 67, y: -14 },
            speechKeywords: "gösterge paneli, gösterge, panel, kokpit, i cockpit, peugeot 3D i cockpit, digital gösterge, dijital ekran, 3D ekran, 3D gösterge, kadran, navigasyon, sürüş modları, adaptif cruise control, mesafe ayarı",
        }, {
            id: "screen", coords: { x: 90, y: -14 },
            speechKeywords: "start stop buton, start, stop, buton, dokunmatik ekran, dokunmatik, kapasitif dokunmatik ekran, kapasitif, klima, radyo, ortam rengi, mirror screen, apple car play, apple car, bluetooth, wi-fi",
        }, {
            id: "midconsole", coords: { x: 97, y: -85 },
            speechKeywords: "orta konsol, konsol, şanzıman, quickshift, kablosuz şarj, şarj, vites",
        }, {
            id: "usbports", coords: { x: -89, y: -65 },
            speechKeywords: "usb, usb giriş, usb bağlantı",
        }, {
            id: "massagebuttons", coords: { x: 71, y: -62 },
            speechKeywords: "masaj, düğme, masaj düğmeleri",
        }, {
            id: "trunk-i", coords: { x: -89, y: -13 },
            speechKeywords: "bagaj",
        }, {
            id: "speakers", coords: { x: 47, y: -10 },
            speechKeywords: "hoparlör, focal, fokal, ses, ses sistemi",
        }, {
            id: "gearbox", coords: { x: 85, y: -48 },
            speechKeywords: "vites, vites kutusu",
        },
    ],
    pinFrames: [{
        index: 0, pins: [
            { id: "lights", posXPerc: 36, posYPerc: 56 },
            { id: "mirrors", posXPerc: 32, posYPerc: 45 },
            { id: "engine", posXPerc: 50, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 50, posYPerc: 61 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 1, pins: [
            { id: "lights", posXPerc: 42, posYPerc: 55 },
            { id: "mirrors", posXPerc: 35, posYPerc: 45 },
            { id: "engine", posXPerc: 57, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 59, posYPerc: 61 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 2, pins: [
            { id: "lights", posXPerc: 51, posYPerc: 55 },
            { id: "mirrors", posXPerc: 38, posYPerc: 45 },
            { id: "engine", posXPerc: 65, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 68, posYPerc: 61 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 3, pins: [
            { id: "lights", posXPerc: 58, posYPerc: 55 },
            { id: "mirrors", posXPerc: 41, posYPerc: 45 },
            { id: "engine", posXPerc: 72, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 75, posYPerc: 61 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 4, pins: [
            { id: "lights", posXPerc: 66, posYPerc: 55 },
            { id: "mirrors", posXPerc: 44, posYPerc: 45 },
            { id: "engine", posXPerc: 76, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 81, posYPerc: 60 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 5, pins: [
            { id: "lights", posXPerc: 73, posYPerc: 55 },
            { id: "mirrors", posXPerc: 49, posYPerc: 45 },
        ]
    }, {
        index: 6, pins: [
            { id: "lights", posXPerc: 79, posYPerc: 55 },
            { id: "backlights", posXPerc: 16, posYPerc: 45 },
            { id: "mirrors", posXPerc: 53, posYPerc: 45 },
        ]
    }, {
        index: 7, pins: [
            { id: "lights", posXPerc: 83, posYPerc: 55 },
            { id: "backlights", posXPerc: 14, posYPerc: 45 },
            { id: "mirrors", posXPerc: 57, posYPerc: 45 },
        ]
    }, {
        index: 8, pins: [
            { id: "lights", posXPerc: 85, posYPerc: 54 },
            { id: "backlights", posXPerc: 13, posYPerc: 45 },
            { id: "mirrors", posXPerc: 60, posYPerc: 45 },
        ]
    }, {
        index: 9, pins: [
            { id: "lights", posXPerc: 87, posYPerc: 54 },
            { id: "backlights", posXPerc: 13, posYPerc: 45 },
            { id: "mirrors", posXPerc: 64, posYPerc: 45 },
        ]
    }, {
        index: 10, pins: [
            { id: "backlights", posXPerc: 15, posYPerc: 45 },
            { id: "mirrors", posXPerc: 67, posYPerc: 45 },
        ]
    }, {
        index: 11, pins: [
            { id: "backlights", posXPerc: 18, posYPerc: 45 },
            { id: "mirrors", posXPerc: 69, posYPerc: 45 },
        ]
    }, {
        index: 12, pins: [
            { id: "backlights", posXPerc: 22, posYPerc: 45 },
            { id: "mirrors", posXPerc: 70, posYPerc: 45 },
        ]
    }, {
        index: 13, pins: [
            { id: "backlights", posXPerc: 29, posYPerc: 45 },
            { id: "mirrors", posXPerc: 71, posYPerc: 45 },
            { id: "trunk", posXPerc: 19, posYPerc: 51 },
        ]
    }, {
        index: 14, pins: [
            { id: "backlights", posXPerc: 36, posYPerc: 45 },
            { id: "mirrors", posXPerc: 71, posYPerc: 45 },
            { id: "trunk", posXPerc: 23, posYPerc: 51 },
        ]
    }, {
        index: 15, pins: [
            { id: "backlights", posXPerc: 45, posYPerc: 45 },
            { id: "mirrors", posXPerc: 70, posYPerc: 45 },
            { id: "trunk", posXPerc: 28, posYPerc: 51 },
        ]
    }, {
        index: 16, pins: [
            { id: "backlights", posXPerc: 54, posYPerc: 45 },
            { id: "mirrors", posXPerc: 69, posYPerc: 45 },
            { id: "trunk", posXPerc: 35, posYPerc: 51 },
        ]
    }, {
        index: 17, pins: [
            { id: "backlights", posXPerc: 32, posYPerc: 45 },
            { id: "mirrors", posXPerc: 67, posYPerc: 45 },
            { id: "trunk", posXPerc: 44, posYPerc: 51 },
        ]
    }, {
        index: 18, pins: [
            { id: "backlights", posXPerc: 35, posYPerc: 45 },
            { id: "trunk", posXPerc: 50, posYPerc: 51 },
        ]
    }, {
        index: 19, pins: [
            { id: "backlights", posXPerc: 40, posYPerc: 45 },
            { id: "mirrors", posXPerc: 34, posYPerc: 45 },
            { id: "trunk", posXPerc: 58, posYPerc: 51 },
        ]
    }, {
        index: 20, pins: [
            { id: "backlights", posXPerc: 48, posYPerc: 45 },
            { id: "mirrors", posXPerc: 33, posYPerc: 45 },
            { id: "trunk", posXPerc: 65, posYPerc: 51 },
        ]
    }, {
        index: 21, pins: [
            { id: "backlights", posXPerc: 56, posYPerc: 45 },
            { id: "mirrors", posXPerc: 32, posYPerc: 45 },
            { id: "trunk", posXPerc: 71, posYPerc: 51 },
        ]
    }, {
        index: 22, pins: [
            { id: "backlights", posXPerc: 64, posYPerc: 45 },
            { id: "mirrors", posXPerc: 31, posYPerc: 45 },
            { id: "trunk", posXPerc: 76, posYPerc: 51 },
        ]
    }, {
        index: 23, pins: [
            { id: "backlights", posXPerc: 71, posYPerc: 45 },
            { id: "mirrors", posXPerc: 31, posYPerc: 45 },
            { id: "trunk", posXPerc: 80, posYPerc: 51 },
        ]
    }, {
        index: 24, pins: [
            { id: "backlights", posXPerc: 77, posYPerc: 45 },
            { id: "mirrors", posXPerc: 31, posYPerc: 45 },
        ]
    }, {
        index: 25, pins: [
            { id: "backlights", posXPerc: 83, posYPerc: 45 },
            { id: "mirrors", posXPerc: 32, posYPerc: 45 },
        ]
    }, {
        index: 26, pins: [
            { id: "backlights", posXPerc: 85, posYPerc: 45 },
            { id: "mirrors", posXPerc: 33, posYPerc: 45 },
        ]
    }, {
        index: 27, pins: [
            { id: "lights", posXPerc: 15, posYPerc: 53 },
            { id: "backlights", posXPerc: 87, posYPerc: 45 },
            { id: "mirrors", posXPerc: 38, posYPerc: 45 },
        ]
    }, {
        index: 28, pins: [
            { id: "lights", posXPerc: 16, posYPerc: 53 },
            { id: "backlights", posXPerc: 87, posYPerc: 45 },
            { id: "mirrors", posXPerc: 41, posYPerc: 45 },
        ]
    }, {
        index: 29, pins: [
            { id: "lights", posXPerc: 18, posYPerc: 54 },
            { id: "backlights", posXPerc: 86, posYPerc: 45 },
            { id: "mirrors", posXPerc: 44, posYPerc: 45 },
        ]
    }, {
        index: 30, pins: [
            { id: "lights", posXPerc: 23, posYPerc: 54 },
            { id: "mirrors", posXPerc: 48, posYPerc: 45 },
        ]
    }, {
        index: 31, pins: [
            { id: "lights", posXPerc: 28, posYPerc: 55 },
            { id: "mirrors", posXPerc: 52, posYPerc: 45 },
        ]
    }, {
        index: 32, pins: [
            { id: "lights", posXPerc: 34, posYPerc: 55 },
            { id: "mirrors", posXPerc: 56, posYPerc: 45 },
            { id: "engine", posXPerc: 23, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 19, posYPerc: 59 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 33, pins: [
            { id: "lights", posXPerc: 42, posYPerc: 55 },
            { id: "mirrors", posXPerc: 60, posYPerc: 45 },
            { id: "engine", posXPerc: 28, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 26, posYPerc: 60 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 34, pins: [
            { id: "lights", posXPerc: 51, posYPerc: 55 },
            { id: "mirrors", posXPerc: 63, posYPerc: 45 },
            { id: "engine", posXPerc: 35, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 33, posYPerc: 60 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    }, {
        index: 35, pins: [
            { id: "lights", posXPerc: 59, posYPerc: 55 },
            { id: "mirrors", posXPerc: 66, posYPerc: 45 },
            { id: "engine", posXPerc: 42, posYPerc: 51 },
            { id: "frontdesign", posXPerc: 42, posYPerc: 60 },
            { id: "sunroof", posXPerc: 50, posYPerc: 33 },
        ]
    },]
} as IDataCar;